/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//SWIPERSJS -- SLIDERS
@import '~swiper/scss';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper';


.md,
.ios,
.wp {
  .custom-alert-danger {
    > div {
      background: #fff !important; // if you don't use .md, .ios, .mp initially, you'd have to use !important here.
      text-align: center !important;
      zoom: 1;
    }

    .alert-head{
      text-align: center !important;
    }
  }
}

.modal-fullscreen{
    --height: 100%;
    --width: 100%;
}
.my-custom-class{
    --border-radius: 20px;
    --width: 90%;
    --height: 80%;
}

.next {
    background: #fff;
    font-size: 25px;
}

.back {
    background: #fff;
    font-size: 25px;
}

.close {
    background: #fff;
    font-size: 25px;
}

.tour-overlay{
    background: #666;
    opacity: 0.5;
    z-index: 9996;
    min-height: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .tour-stop{
    position: relative;
    z-index: 9998;
    //outline: 10px solid white;
  }
  
  .tour-stop-bg{
    //background: white; 
    outline: 10px solid white;
    border-radius: 35px;
    position: fixed;
    z-index: 9997;
  }
  
  .tour-message{
    position: fixed;
    z-index: 9997;
    top: 0;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.26);
  }
  
  .tour-message .title{
    font-size: 1.75em;
    margin: 0;
    margin-bottom: 10px;
  }
  
  .tour-message__controls{
    font-size: 1.5em;
  }

@media only screen and (max-width: 600px) {   
.my-custom-class{
    --height: 100%;
    --width: 100%;
    --border-radius: 0;
}
.container {
    width: 100% !important;
}
}
body {
    font-family: Lato;
  }

  ion-calendar{

    .title .switch-btn{
        color: #000 !important;
    }
    .title .back{
        border: 3px #000 solid !important;
        border-radius: 50% !important;
        overflow: auto !important;
        color: #000 !important;
        opacity: 1 !important;

    }

    .title .forward {
        border: 3px #000 solid !important;
        border-radius: 50% !important;
        overflow: auto !important;
        color: #000 !important;
        opacity: 1 !important;

    }

    .switch-btn{
        color: #000 !important;
        font-weight: bold;
    }

    .days-btn{
        font-weight: bold;
    }
}

.radio-icon{
    background: #fff !important;
}

body.swal2-height-auto {
    height: 100% !important;
}

.swal2-height-auto {
    height: 100% !important;
}

//Estilo Barra Busqueda Header
ion-searchbar {
    background: #fff !important;
    border-radius: 30px;
    overflow: hidden;
   .searchbar-input-container {
       background: #fff !important;
       box-shadow: none !important;
       input{
          background: #fff !important;
          box-shadow: none !important;
       }
   }
  }

ion-header {
    padding: 5px;
    background: #11B7B7;
}

.chooseStartEnd{
    --width: 45% !important;
    --height: 35% !important; 
    --border-radius: 15px;
  }


  @media screen and (aspect-ratio: 9/16) {
    .chooseStartEnd{
        --height: 15% !important;
        transform: scale(1.5);
      }

      .detailsModal{
        --width: 80% !important;
        --height: 50% !important; 
        --border-radius: 15px;
    }

    .md,
    .ios,
    .wp {
      .custom-alert-danger {
        > div {
          background: #fff !important; // if you don't use .md, .ios, .mp initially, you'd have to use !important here.
          text-align: center !important;
          zoom: 2;
        }
  
        .alert-head{
          text-align: center !important;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .chooseStartEnd{
        --width: 100% !important;
        --height: 100% !important; 
        --border-radius: 0px;
    }

    .detailsModal{
        --width: 90% !important;
        --height: 90% !important; 
        --border-radius: 15px;
    }
}